<template>
  <div class="d-flex flex-column flex-grow-1 rounded mr-md-3">
    <BaseToolBar
      title="Suivis des clients"
      subtitle="Liste des suivis / visites réalisées"
    >
      <button
        class="btn btn-primary"
        v-tooltip="'Ajouter un suivi'"
        @click="createSuivi(id)"
      >
        Ajouter
      </button>
    </BaseToolBar>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="w-100" v-if="suivis">
        <div v-for="suivi in suivis" :key="suivi.id" class="w-100">
          <SuiviItem :suivi="suivi" :lists="lists"></SuiviItem>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import SuiviItem from "@/components/clients/SuiviItem.vue";
export default {
  components: { BaseToolBar, SuiviItem },
  data() {
    return {
      lists: {},
      feedback: {}
    };
  },
  props: {},
  computed: {
    ...mapFields("clients", ["client.id"]),
    ...mapMultiRowFields("clients", ["suivis"])
  },
  methods: {
    ...mapActions({
      _createSuivi: "clients/createSuivi"
    }),
    createSuivi: function(d) {
      this.feedback = {};
      this._createSuivi(d).catch(error => this.errHandler(error));
    },
    errHandler: function(error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    }
  },
  mounted() {}
};
</script>
