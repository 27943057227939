<template>
  <container-list-extend title="Visite" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div class="p-1 p-md-2 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <h6 class="m-0 ml-1" v-if="suivi.description">{{suivi.description}}</h6>
        <h6 class="m-0 ml-1" v-else>Suivi n°{{ suivi.id }}</h6>
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small>
          <div v-if="suivi.suivi_date">
            Le :
            {{ $moment(suivi.suivi_date).format("DD/MM/YYYY") }}
          </div>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <base-form
        deleteBtn
        @click="updateSuivi(suivi)"
        @delete="deleteSuivi(suivi)"
        :loading="loading"
      >
        <base-form-row row>
          <base-input
            v-model="suivi.description"
            inputText="Libellé"
            :errors="feedback.description"
          ></base-input>
          <base-inputDatePicker
            v-model="suivi.suivi_date"
            inputText="Date de suivi"
            :errors="feedback.suivi_date"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-input-area
            v-model="suivi.commentaire"
            text="Synthèse"
            :errors="feedback.commentaire"
          ></base-input-area>
        </base-form-row>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseInput from "@/components/bases/Input.vue";

export default {
  name: "FormationsItem",
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    BaseInput
  },
  data() {
    return {
      feedback: {},
      loading: false
    };
  },
  props: {
    suivi: {
      Type: Object,
      Requierd: true,
      Default: {}
    },
    lists: {
      Type: Object,
      Requierd: true,
      Default: {}
    }
  },
  computed: {
    ...mapGetters({ data: "clients/data" })
  },
  methods: {
    ...mapActions({
      _updateSuivi: "clients/updateSuivi",
      _deleteSuivi: "clients/deleteSuivi"
    }),
    updateSuivi: function(suivi) {
      this.feedback = {};
      this.loading = true;
      this._updateSuivi(suivi)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteSuivi: function(suivi) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null
      this.feedback = {};
      this.loading = true;
      this._deleteSuivi(suivi)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style lang="css"></style>